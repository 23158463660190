import { Text } from '@workos-inc/component-library';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Link } from 'components/link';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsOneLogin: ArrayStepsConfig = {
  providerLabel: 'OneLogin SCIM v2',
  type: 'Array',
  steps: [
    {
      name: 'Select or Create OneLogin Application',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Log in to the OneLogin admin dashboard and select the "Applications"
            tab at the top. Select "Add App".
          </Text>

          <Img
            className="my-8"
            height={1196}
            src="/images/c5f77445-e09f-4e21-8911-a240e6deee26.png"
            width={2136}
          />

          <Text>
            Search for "scim" in the text field and select the Application with
            type "SCIM Provisioner with SAML (SCIM V2 Enterprise)".
          </Text>

          <Img
            className="my-8"
            height={1248}
            src="/images/a47d721e-db6f-490b-b3e0-9ac284bd3464.png"
            width={2304}
          />

          <Text>
            Give your Application a descriptive Display Name and hit "Save".
          </Text>

          <Img
            className="my-8"
            height={1430}
            src="/images/4dd4042a-847e-4fde-b835-750a2f0c5644.png"
            width={2076}
          />

          <Confirm
            label="I’ve selected or created my OneLogin application."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Configure OneLogin SCIM',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        onNextStep: handleNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>
            Within the SCIM Application, select the "Configuration" tab on the
            left.
          </Text>

          <Text>
            Copy and paste the endpoint below into the "SCIM Base URL"
          </Text>

          <CopyInput label="Copy this Endpoint" value={directory?.endpoint} />

          <Text>
            Then, copy and paste the Bearer Token below into the "SCIM Bearer
            Token" field.
          </Text>

          <CopyInput
            label="Copy this Bearer Token"
            type="password"
            value={directory?.bearerToken}
          />

          <Text>Hit "Enable" under "API Status" and then hit "Save".</Text>

          <Img
            className="my-8"
            height={1868}
            src="/images/54c23dd5-1a05-448e-8a1b-255dc8a95b1b.png"
            width={2062}
          />

          <Text>
            Select the "Provisioning" tab on the left. Check the "Enable
            provisioning" box and hit "Save".
          </Text>

          <Img
            className="my-8"
            height={1278}
            src="/images/94f76ad5-30ad-4d65-a3c7-6a18ec0e0683.png"
            width={2040}
          />

          <Text>
            Select the "Parameters" tab on the left. Then select "Groups".
          </Text>

          <Img
            className="my-8"
            height={1426}
            src="/images/54c10855-69e5-4484-8ccd-44f2fe89baf3.png"
            width={2122}
          />

          <Text>
            In the modal that pops up, check the box next to "Include in User
            Provisioning" and hit "Save".
          </Text>

          <Img
            className="my-8"
            height={1426}
            src="/images/09795f59-f7c0-4b04-97d5-9044fb05d987.png"
            width={2122}
          />

          <Confirm
            label="I’ve confirmed, activated and saved the configuration settings."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Assign Users to your Application',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            In order for your users and groups to be synced, you will need to
            assign them to your OneLogin Application. Select "Users" from the
            top navigation menu.
          </Text>

          <Text>
            Next, find a user you'd like to provision to the SCIM app. Within
            that user profile, select the "Applications" tab on the left. Then,
            click the "+" symbol.
          </Text>

          <Img
            className="my-8"
            height={1210}
            src="/images/ee1e01fa-26f3-49e1-8515-3fedfbdee14b.png"
            width={2358}
          />

          <Text>Select the appropriate app and hit "Continue".</Text>

          <Img
            className="my-8"
            height={1210}
            src="/images/f00d45d9-7128-43dd-8bdb-cc48e7eebf7b.png"
            width={2358}
          />

          <Text>Select "Save" in the next modal to confirm the change.</Text>

          <Img
            className="my-8"
            height={1764}
            src="/images/e9e5ef85-5efb-4b71-8283-e8d570f3103e.png"
            width={2478}
          />

          <Confirm
            label="I’ve completed user provisioning."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Assign Groups to your Application',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            NOTE: There are many ways to provision groups in OneLogin. Below is
            one method that we recommend, but other methods can be used.
          </Text>

          <Text>
            In the top navigation, Select "Users" and then "Roles" from the
            dropdown. Select "New Role".
          </Text>

          <Img
            className="my-8"
            height={1392}
            src="/images/ecb269b5-bfe0-41f7-b73f-11a152ac0197.png"
            width={2086}
          />

          <Text>
            Give the Role a name (this will be the name of the group), select
            the appropriate SCIM application, and hit "Save".
          </Text>

          <Img
            className="my-8"
            height={1392}
            src="/images/9924f509-c60a-47b5-ae30-017c361d1b55.png"
            width={2086}
          />

          <Text>
            Click the "Users" tab for the role. Search for any users you'd like
            to assign to that role and hit "Add To Role". Then hit "Save".
          </Text>

          <Img
            className="my-8"
            height={1276}
            src="/images/b4a4be2a-f75f-4dfe-adb9-dea585be0fce.png"
            width={2046}
          />

          <Text>Click "Save" in the next modal to confirm.</Text>

          <Img
            className="my-8"
            height={1276}
            src="/images/0a6ebdb2-3896-4481-8e11-ca9f1a23ecd0.png"
            width={2046}
          />

          <Text>
            Navigate back to your SCIM app and click on the "Rules" tab on the
            left. Then, hit "Add Rule".
          </Text>

          <Img
            className="my-8"
            height={1276}
            src="/images/317311e1-4fac-487f-a30e-2aeeb6e0d30a.png"
            width={2046}
          />

          <Text>
            Give your Rule a name. Under "Actions", select "Set Groups in
            your-app-name". Then, set it to "For each role with value that
            matches your-role-name". Hit "Save".
          </Text>

          <Img
            className="my-8"
            height={1560}
            src="/images/02d19425-34e2-45b3-bb64-bc3962714493.png"
            width={2136}
          />

          <Text>
            Within your SCIM app under the Users tab, you may then need to click
            on any "Pending" notifications to confirm the update for users.
          </Text>

          <Img
            className="my-8"
            height={1244}
            src="/images/3aa55536-48e8-4322-9e5e-7227c4099f9c.png"
            width={2140}
          />

          <Confirm
            label="I’ve completed my assignments."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      // eslint-disable-next-line react/display-name
      render: (directoryStepProps: DirectoryStepProps) => (
        <MapAttributesStep {...directoryStepProps}>
          <Text>
            There are additional attributes needed by the application for it to
            fully function. Note that basic attributes like a user's name or
            email are already mapped, and so are not present below.
          </Text>

          <Text>
            OneLogin offers the following list of{' '}
            <Link
              newTab
              appearance="highlight"
              href={'https://developers.onelogin.com/scim/define-user-schema'}
            >
              default attribute mappings
            </Link>{' '}
            in their documentation. We support both the Core and Enterprise User
            Schemas from OneLogin.
          </Text>

          <Text>
            Map attributes from your Directory Provider's responses to the
            attributes shown below.
          </Text>
        </MapAttributesStep>
      ),
    },
  ],
};
